<template>
	<v-layout>
		<Dialog :dialog="dialog" :dialogWidth="dialogWidth">
			<template v-slot:title>
				<v-layout>
					<v-flex> Manage Reason </v-flex>
					<v-flex class="text-right">
						<v-btn small class="mx-4" v-on:click="createContractType">
							<span class="font-size-16 font-weight-600">Add Reason</span>
						</v-btn>
					</v-flex>
				</v-layout>
			</template>
			<template v-slot:body>
				<perfect-scrollbar
					:options="{ suppressScrollX: true }"
					class="scroll custom-box-top-inner-shadow"
					style="max-height: 90vh; position: relative"
				>
					<v-container class="py-0">
						<v-form
							ref="categoryForm"
							v-model.trim="formValid"
							lazy-validation
							v-on:submit.stop.prevent="updateReasonSetting"
						>
							<v-row class="py-0">
								<v-col md="12" class="py-0">
									<v-simple-table>
										<tbody>
											<tr v-for="(type, index) in reasonList" :key="index">
												<td width="90%">
													<TextInput
														hide-details
														:disabled="pageLoading"
														placeholder="Enter Reason"
														:loading="pageLoading"
														:id="`reasons-${index}`"
														v-model="type.text"
														:class="{
															required: !type.text,
														}"
														:rules="[vrules.required(type.text, 'Reason')]"
													></TextInput>
												</td>
												<td width="50">
													<v-tooltip top content-class="custom-top-tooltip">
														<template v-slot:activator="{ on, attrs }">
															<v-icon
																v-on:click="deleteContractType(index, type)"
																color="deep-orange"
																v-bind="attrs"
																v-on="on"
																>mdi-delete</v-icon
															>
														</template>
														<span>Click here to delete</span>
													</v-tooltip>
												</td>
											</tr>
										</tbody>
									</v-simple-table>
								</v-col>
							</v-row>
						</v-form>
					</v-container>
				</perfect-scrollbar>
			</template>
			<template v-slot:action>
				<v-btn
					:disabled="pageLoading"
					:loading="pageLoading"
					class="mx-2 custom-grey-border custom-bold-button white--text"
					color="cyan"
					v-on:click="updateReasonSetting"
				>
					Save
				</v-btn>
				<v-btn
					:disabled="pageLoading"
					class="mx-2 custom-grey-border custom-bold-button"
					v-on:click="$emit('close-dialog', true)"
				>
					Close
				</v-btn>
			</template>
		</Dialog>
		<DeleteTemplate
			type="Reasons"
			:delete-text="deleteText"
			v-on:success="removeReason"
			v-on:close="deleteDialog = false"
			:delete-dialog="deleteDialog"
			:delete-url="deleteURL"
		>
		</DeleteTemplate>
	</v-layout>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/core/services/store/common.module";
import Dialog from "@/view/components/Dialog";
import ApiService from "@/core/services/api.service";
import TextInput from "@/view/components/TextInput";
import { toSafeInteger, cloneDeep } from "lodash";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";
import DeleteTemplate from "@/view/components/DeleteTemplate";

export default {
	props: {
		dialog: {
			type: Boolean,
			required: true,
			default: false,
		},
		reasons: {
			type: Array,
			default: () => {
				return new Array();
			},
		},
	},
	data: () => {
		return {
			pageLoading: false,
			reasonList: [],
			deleteURL: null,
			deleteId: null,
			deleteDialog: false,
			deleteIndex: null,
			deleteText: null,
		};
	},
	watch: {
		reasons: {
			deep: true,
			immediate: true,
			handler(param) {
				this.reasonList = cloneDeep(param);
			},
		},
	},
	methods: {
		updateReasonSetting() {
			const _this = this;
			const formErrors = _this.validateForm(_this.$refs.categoryForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}
			if (!_this.$refs.categoryForm.validate()) {
				return false;
			}
			_this.pageLoading = true;
			ApiService.setHeader();
			ApiService.post(`setting/retire-reason`, { reasons: _this.reasonList })
				.then(({ data }) => {
					_this.$store.dispatch(SET_LOCAL_DB);
					_this.$emit("close-dialog", true);
					_this.$emit("success", data);
				})
				.catch((error) => {
					_this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		createContractType() {
			this.reasonList.push({
				text: null,
				value: null,
				id: null,
			});
		},
		deleteContractType(index, data) {
			if (this.reasonList.length > 1) {
				if (data && data.value) {
					console.log(data);
					this.deleteDialog = true;
					this.deleteId = data.value;
					this.deleteIndex = index;
					this.deleteText = data.text;
					this.deleteURL = "setting/retire-reason/" + data.id;
				} else {
					this.reasonList.splice(index, 1);
				}
			}
		},
		removeReason() {
			if (this.deleteIndex) {
				this.reasonList.splice(this.deleteIndex, 1);
				this.deleteIndex = null;
				this.deleteDialog = false;
				this.$store.dispatch(SET_LOCAL_DB);
				this.$emit("success", this.reasonList);
			}
		},
	},
	components: {
		Dialog,
		DeleteTemplate,
		TextInput,
	},
	mounted() {
		//this.reasonList = cloneDeep(this.reasons);
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 50);
		},
	},
};
</script>
