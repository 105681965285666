<template>
	<div>
		<Dialog :dialog="retireDialog" :dialog-width="700">
			<template v-slot:title>Retire {{ endpoint == "inventory" ? "Perishables" : type }}</template>
			<template v-slot:body>
				<v-form
					ref="retireForm"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="onSubmit"
				>
					<v-row class="retire-dialog">
						<v-col md="12">
							<perfect-scrollbar
								:options="{ suppressScrollX: true }"
								class="scroll"
								style="max-height: 60vh; position: static"
							>
								<v-list class="mx-2 mx-md-4 border border-secondary" subheader three-line>
									<v-list-item>
										<v-list-item-avatar v-if="relatedDetails">
											<ImageTemplate circle :src="relatedDetails.image"></ImageTemplate>
										</v-list-item-avatar>
										<v-list-item-content v-if="relatedDetails">
											<v-list-item-title class="font-size-16">
												<Chip :text="relatedDetails.barcode"></Chip>
											</v-list-item-title>
											<v-list-item-subtitle class="font-size-14">
												<b>{{ relatedDetails.name }}</b>
											</v-list-item-subtitle>
										</v-list-item-content>
										<v-list-item-action class="margin-auto" v-if="relatedDetails">
											<v-list-item-action-text class="font-size-16"
												><b>Cost</b> - {{ relatedDetails.cost_price_formatted }}
											</v-list-item-action-text>
										</v-list-item-action>
									</v-list-item>
								</v-list>
							</perfect-scrollbar>
						</v-col>
						<template v-if="false">
							<v-col md="3" class="my-auto py-0">
								<label for="reason" class="btx-label mt-3 required">Reason</label>
							</v-col>
							<v-col md="9" class="py-0">
								<AutoCompleteInput
									hide-details
									:items="reasonList"
									:disabled="pageLoading"
									:loading="pageLoading"
									id="reason"
									:rules="[vrules.required(retire.reason, 'Reason')]"
									:class="{
										required: !retire.reason,
									}"
									placeholder="Reason"
									v-model="retire.reason"
								></AutoCompleteInput>
							</v-col>
						</template>
						<template v-if="endpoint == 'asset'">
							<v-col md="3" class="my-auto py-0">
								<label for="retire-date" class="btx-label mt-3 required">Retire Date</label>
							</v-col>
							<v-col md="9" class="py-0">
								<DatePicker
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									id="retire-date"
									placeholder="Retire Date"
									:rules="[vrules.required(retire.retire_date, 'Retire Date')]"
									:class="{
										required: !retire.retire_date,
									}"
									v-model="retire.retire_date"
								></DatePicker>
							</v-col>
						</template>
						<v-col md="3" class="my-auto py-0">
							<label for="reason" class="btx-label mt-3">Reason</label>
						</v-col>
						<v-col md="9" class="py-0">
							<AutoCompleteInput
								hide-details
								:items="reasonList"
								:disabled="pageLoading"
								:loading="pageLoading"
								id="reason"
								placeholder="Reason"
								v-model="retire.reason"
								v-on:click:append-outer="manageReasonDialog = true"
								append-outer-icon="mdi-cog"
							></AutoCompleteInput>
						</v-col>
						<v-col md="3" class="py-0">
							<label :for="`attachment-${uniqFieldId}`" class="btx-label mt-3">Attachments</label>
						</v-col>
						<v-col md="9" class="py-0">
							<v-file-input
								:id="`attachment-${uniqFieldId}`"
								v-model="attachment"
								color="blue darken-4"
								small-chips
								placeholder="Click here to select files"
								multiple
								prepend-icon=""
								class="ctx-attachment-field mt-3 pt-0"
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								prepend-inner-icon="mdi-attachment mdi-rotate-315"
								outlined
								v-on:change="limitAttachment($event)"
							></v-file-input>
							<span class="font-small">Max 3 files allowed.</span>
						</v-col>
						<v-col md="3" class="py-0">
							<label for="comment" class="btx-label mt-3">Comments</label>
						</v-col>
						<v-col md="9" class="py-0">
							<TextAreaInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								id="comment"
								placeholder="Comments"
								v-model="retire.comment"
							></TextAreaInput>
						</v-col>
						<template v-if="false">
							<v-col md="3" class="py-0 mt-2">
								<label :for="`comment-${uniqFieldId}`" class="btx-label mt-3">Signature</label>
							</v-col>
							<v-col md="9" class="py-0">
								<div class="signature-pad" id="signature-pad">
									<div ref="signature_div" class="custom-border-grey-dashed mt-2" style="width: 100%">
										<canvas ref="cc_signature"></canvas>
									</div>
								</div>
							</v-col>
						</template>
					</v-row>
				</v-form>
			</template>
			<template v-slot:action>
				<v-btn
					v-if="false"
					class="white--text"
					:loading="pageLoading"
					:disabled="!formValid || pageLoading"
					depressed
					color="blue darken-4"
					tile
					v-on:click="onSubmit()"
				>
					Retire
				</v-btn>
				<v-btn
					class="white--text"
					:loading="pageLoading"
					:disabled="!formValid || pageLoading"
					depressed
					color="blue darken-4"
					tile
					v-on:click="addToCart()"
				>
					Add To Cart
				</v-btn>
				<v-btn depressed tile :disabled="pageLoading" v-on:click="$emit('close', true)"> Close </v-btn>
			</template>
		</Dialog>
		<template v-if="manageReasonDialog">
			<ManageRetireReason
				:dialog="manageReasonDialog"
				:reasons="reasonList"
				v-on:close-dialog="manageReasonDialog = false"
				v-on:success="getReasons"
			></ManageRetireReason>
		</template>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import Dialog from "@/view/components/Dialog";
import ApiService from "@/core/services/api.service";
import { SET_ERROR } from "@/core/services/store/common.module";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import DatePicker from "@/view/components/DatePicker";
import ImageTemplate from "@/view/components/Image";
import Chip from "@/view/components/Chip";
import { CART_COUNT } from "@/core/services/store/config.module";
import store from "@/core/services/store";
import ManageRetireReason from "@/view/components/Manage-Retire-Reason.vue";
import { UploadFiles } from "@/core/lib/upload.lib";

export default {
	name: "retire-template",
	title: "Retire Template",
	props: {
		type: {
			type: String,
			default: null,
		},
		typeUuid: {
			type: String,
			default: null,
		},
		endpoint: {
			type: String,
			default: null,
		},
		retireDialog: {
			type: Boolean,
			default: false,
		},
		cartUuid: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			formValid: true,
			pageLoading: false,
			reasonList: [],
			attachment: [],
			manageReasonDialog: false,
			relatedDetails: {},
			signature: null,
			retire: {
				reason: null,
				retire_date: null,
				signature: null,
				attachment: [],
				comment: null,
			},
		};
	},
	watch: {
		retireDialog(param) {
			if (param) {
				this.init();
			}
		},
		typeUuid: {
			deep: true,
			immediate: true,
			handler(param) {
				if (param) {
					this.getRelatedDetails(this.type, param);
				}
			},
		},
	},
	components: {
		Dialog,
		DatePicker,
		TextAreaInput,
		ManageRetireReason,
		AutoCompleteInput,
		ImageTemplate,
		Chip,
	},
	methods: {
		limitAttachment(output) {
			if (this.pageLoading) {
				return false;
			}

			this.attachment = output.slice(0, 3);

			if (this.attachment.length <= 0) {
				return false;
			}

			const request = new FormData();

			for (let i = 0; i < this.attachment.length; i++) {
				request.append(`files[${i}]`, this.attachment[i]);
			}

			this.pageLoading = true;

			UploadFiles(request)
				.then((response) => {
					this.retire.attachment = response;
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		init() {
			this.retire = {
				reason: null,
				retire_date: null,
				signature: null,
				attachment: [],
				comment: null,
			};
			this.initSignature();
			if (this.cartUuid) {
				this.getCartRetired();
			}
		},
		initSignature() {
			/*const _this = this;
			_this.$nextTick(() => {
				let ccanvas = _this.$refs["cc_signature"];
				let ccanvasDiv = _this.$refs["signature_div"];
				ccanvas.setAttribute("width", 500);
				ccanvasDiv.setAttribute("style", "width:" + "500px");
				_this.signature = new SignaturePad(ccanvas);
			});*/
		},
		getRelatedDetails(type, typeUuid) {
			this.pageLoading = true;

			ApiService.get(`${this.endpoint}/${typeUuid}`)
				.then(({ data }) => {
					this.relatedDetails = data;
					if (type != "Asset") {
						this.relatedDetails.cost_price_formatted = data.price_add_stock_formatted;
					}
				})
				.catch((error) => {
					//this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		onSubmit() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.retireForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.retireForm.validate()) {
				return false;
			}

			this.pageLoading = true;
			// this.retire.signature = this.signature.toDataURL();
			ApiService.patch(`${this.endpoint}/${this.typeUuid}/retire`, this.retire)
				.then(() => {
					this.$emit("success");
					this.$emit("close");
				})
				.catch(() => {
					this.$emit("error");
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		addToCart() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.retireForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.retireForm.validate()) {
				return false;
			}

			const cartData = {
				retire_date: this.retire.retire_date,
				retire_reason: this.retire.reason,
				indefinitely: false,
				comment: this.retire.comment,
				attachment: this.retire.attachment,
				signature: null, //this.signature.toDataURL(),
				related_type: this.type,
				replace: false,
			};

			this.pageLoading = true;
			// this.retire.signature = this.signature.toDataURL();
			ApiService.post(`cart/${this.typeUuid}/retire`, cartData)
				.then(() => {
					this.$emit("success");
					this.$emit("close");
					store.dispatch(CART_COUNT);
				})
				.catch(() => {
					this.$emit("error");
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getReasons(data) {
			this.reasonList = data;
		},
		getCartRetired() {
			this.pageLoading = true;
			ApiService.get(`cart/${this.cartUuid}`)
				.then(({ data }) => {
					(this.retire = {
						reason: data.retire_reason,
						retire_date: data.retire_date,
						signature: null,
						comment: data.description,
					}),
						(this.endpoint = data.asset && data.asset.id > 0 ? "asset" : "asset-stock");
					this.typeUuid = data.asset && data.asset.id > 0 ? data.asset.uuid : data.asset_stock.uuid;
					this.type = data.asset && data.asset.id > 0 ? "Asset" : "asset-stock";

					this.getRelatedDetails(this.type, this.typeUuid);
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	mounted() {
		const today = new Date();
		this.retire.retire_date = this.formatDateRaw(today);

		this.reasonList = this.localDB("retire_reasons", []);
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
	},
};
</script>
